import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices, useTranslation, useWhiteLabelServiceSettings } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayManifestKeys from 'src/constants/locale/key/AciHighwayManifest'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import AciHighwayManifestApiUrls from 'src/apiUrls/AciHighwayManifestApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import pathMap from 'src/paths/pathMap'
import CargoFormProperties from '../../cargo/FormProperties'
import AttachCargoDialog from './AttachCargoDialog'
import CargoDialog from './CargoDialog'
import moment from 'moment'
import axios from 'axios'
import sweetalert from 'sweetalert'
import { Box, Chip, Grid, makeStyles, Select, Tooltip, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../../../components/aciacehighway/Table'
import { getStatusMetadata } from '../../../../common/NACommon'
import CargoUploadDialog from './CargoUploadDialog'
import SetResponseMessageDialog from './SetResponseMessageDialog'
import ConfirmDialog from '../../../common/ConfirmDialog'
import ChargeTypeDialog from '../../../common/ChargeTypeDialog'
import { format } from 'date-fns'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'
import SubmitAllCargoMessageDialog from './SubmitAllCargoMessageDialog'

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, LIKE }
} = constants

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.name === 'ONE_DARK' ? 'transparent' : theme.palette.common.white,
    fontSize: 14,
    maxWidth: '100%',
    width: 150,
    '& > .MuiOutlinedInput-input': {
      padding: 8
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32
    }
  }
}))

function CargoAttachTable(props) {
  const {
    history,
    manifestId,
    onChangeStep,
    loading,
    onSetLoading,
    showNotification,
    step,
    firstExpectedPortValue,
    expectedArrivalDateTimeValue,
    expectedArrivalTimeZoneValue,
    manifestCarrierCode
  } = props

  const [lookups, setLookups] = useState(null)
  const [amendmentCodeOptions, setAmendmentCodeOptions] = useState([])
  const [cargoLoadedInList, setCargoLoadedInList] = useState([]);
  const [attachCargoDialog, setAttachCargoDialog] = useState(false)
  const [cargoDialog, setCargoDialog] = useState({ open: false, cargo: null, isView: false })
  const [setResponseMsgDialog, setSetResponseMsgDialog] = useState({ open: false, cargos: [] })
  const [uploadCargoDialog, setUploadCargoDialog] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, cargo: null })
  const [massDeleteConfirmDialog, setMassDeleteConfirmDialog] = useState({ open: false, idList: null })
  const [submitAllCargoMsgDialog, setSubmitAllCargoMsgDialog] = useState({ open: false })
  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, cargos: [] })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })
  const [isSuperParty, setSuperParty] = useState(false);
  const [isMassSubmit, setMassSubmit] = useState(false);

  const tableRef = useRef(null)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest, updateRecord } = useServices()
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("aci-highway")
  const theme = useTheme()

  useEffect(() => {
    function onSuccess(response) {
      setCargoLoadedInList(response.data)
    }

    function onError(error) {
      console.log(error);
    }

    function onComplete() { }
    const config = {}

    securedSendRequest.execute('POST', AciHighwayCargoApiUrls.GET_CARGO_LOADED, { manifestId }, onSuccess, onError, onComplete, config, null);
  }, []);

  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined, 'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_MANIFEST_COUNTRY' }],
        undefined, 'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'ACI' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_PARTY_TYPE' }],
        undefined, 'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'ACI' }],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Arrival date time zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SCHEDULER_TIME_ZONE' }],
        undefined, 'code'
      ),
      // Amendment codes
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: constants.CodeMaintenanceType.CODE_MASTER },
          filters: [
            { field: 'active', operator: EQUAL, value: 'true' },
            { field: 'codeType', operator: EQUAL, value: 'ACIHW_CARGO_AMENDMENT_CODE' }
          ]
        },
        (response) => {
          return response.content.map((status) => ({ code: status.code, desc: status.descriptionEn }))
        },
        () => {
          showNotification('error', 'Failed to fetch Amendment Code options')
        }
      ),
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined, 'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER, undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([
        status,
        countries,
        countryStates,
        partyType,
        firstExpectedPort,
        arrivalDateAndTimeZone,
        amendmentCodes,
        billingChargeTypes,
        billingSuperParty
      ]) => {
        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          status,
          countries,
          countryStates: states,
          partyType,
          firstExpectedPort,
          arrivalDateAndTimeZone,
        })

        setAmendmentCodeOptions(amendmentCodes)
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  const submitButton = {
    buttonProps: { color: 'primary', size: 'medium', variant: 'outlined', style: { minWidth: 'auto' } },
    disabled: (rows) => {
      let shouldDisable = false

      rows.forEach((rowData) => {
        if (rowData.status === '1008') {
          shouldDisable = true
        }
      })

      return shouldDisable
    },
    icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
    label: translatedTextsObject.submitButton,
    onClick: (rows) => preSubmitCargos(rows),
    tooltip: (rows) => {
      let includesSentRecords = false

      rows.forEach((rowData) => {
        if (rowData.status === '1008') {
          includesSentRecords = true
        }
      })

      return includesSentRecords ? translatedTextsObject.submitSentRecord : null
    }
  }

  const setResponseMsgButton = {
    buttonProps: { color: 'primary', size: 'medium', variant: 'outlined', style: { minWidth: 'auto' } },
    disabled: (rows) => {
      let shouldDisable = false

      rows.forEach((rowData) => {
        if (rowData.status === '1008') {
          shouldDisable = true
        }
      })

      return shouldDisable
    },
    icon: <FontAwesomeIcon icon={['fal', 'cog']} />,
    label: 'Set response message',
    onClick: (rows) => setSetResponseMsgDialog({ open: true, cargos: rows }),
    tooltip: (rows) => {
      let includesSentRecords = false

      rows.forEach((rowData) => {
        if (rowData.status === '1008') {
          includesSentRecords = true
        }
      })

      return includesSentRecords ? translatedTextsObject.submitSentRecord : null
    }
  }

  const bulkPrintA8AButton = {
    buttonProps: { color: 'primary', size: 'medium', variant: 'outlined', style: { minWidth: 'auto' } },
    icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
    label: 'Print A8A',
    onClick: (data) => {
      sweetalert("Printing A8A in the background. The report(s) will be downloaded automatically once ready.", "", "success");
      return handleBulkPrintA8A(data)
    },
    disabled: (rows) => rows.length > 100,
    tooltip: (rows) => rows.length > 100 ? 'Only a maximum of 100 records can be printed per instance' : 'Bulk print A8A for the selected records.',
  }

  const massDeleteButton = {
    buttonProps: { size: 'medium', color: 'secondary', style: { color: theme.palette.error.main, minWidth: 'auto' } },
    disabled: (rows) => {
      let disable = false;
      rows.forEach(e => {
        if (e.status !== '1005') { disable = true }
      })
      return disable;
    },
    icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
    label: "Mass Delete",
    tooltip: (rows) => {
      let description = null;
      rows.forEach(e => {
        if (e.status !== '1005') { description = 'Only draft status can be deleted' }
      })
      return description;
    },

    onClick: (rows) => {
      let idList = rows.map(e => e.id)
      setMassDeleteConfirmDialog({ open: true, idList: idList });
    }
  }

  const detachButton = {
    buttonProps: { size: 'medium', color: 'secondary', style: { color: theme.palette.error.main, minWidth: 'auto' } },
    disabled: (rows) => {
      let shouldDisable = false

      rows.forEach((rowData) => {
        if (
          rowData.status === '1000' ||
          rowData.status === '1008' ||
          rowData.status === '1007' ||
          rowData.status === '1004'
        ) {
          shouldDisable = true
        }
      })

      return shouldDisable
    },
    icon: <FontAwesomeIcon icon={['fal', 'minus-circle']} />,
    label: translatedTextsObject.detachButton,
    onClick: handleDetachCargos,
    tooltip: (rows) => {
      let includesSentRecords = false

      rows.forEach((rowData) => {
        if (rowData.status === '1000' || rowData.status === '1008' || rowData.status === '1007' || rowData.status === '1004') {
          includesSentRecords = true
        }
      })

      return includesSentRecords ? translatedTextsObject.detachSentRecord : null
    }
  }

  const [selectedTopBar, setSelectedTopBar] = useState([submitButton, setResponseMsgButton, massDeleteButton, detachButton])

  useEffect(() => {
    if (whiteLabelServiceSettings.length > 0) {
      let bulkPrintA8A = whiteLabelServiceSettings[0].custom.UI_CARGO_ATTACH_TABLE_BULK_PRINT_A8A
      if (bulkPrintA8A == "true") {
        setSelectedTopBar([submitButton, setResponseMsgButton, bulkPrintA8AButton, massDeleteButton, detachButton])
      }
    } else if (isSuperParty) {
      setSelectedTopBar([submitButton, setResponseMsgButton, bulkPrintA8AButton, massDeleteButton, detachButton])

    }
  }, [isSuperParty])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function makeTranslatedTextsObject() {
    let ccn = translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CCN)
    let cargoType = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CARGO_TYPE
    )
    let acquittalNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ACQUITTAL_NO
    )
    let createdDateTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CREATIONDATETIME
    )
    let arrivalDateAndTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME
    )
    let arrivalDateAndTimeZone = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME_ZONE
    )
    let tripNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.TRIP_NO
    )
    let status = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.STATUS
    )
    let deleteButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.DELETE_BUTTON
    )
    let detachButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.DETACH
    )
    let submitButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_BUTTON
    )
    let cancelButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CANCEL_BUTTON
    )
    let submitSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_SUCCESS_MSG
    )
    let attachCargoSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH_CARGO_SUCESS_MSG
    )
    let detachSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.DETACH_SUCCESS_MSG
    )
    let editButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.EDIT_BUTTON
    )
    let editSentRecord = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.EDIT_SENT_RECORDS
    )
    let cloneButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CLONE_BUTTON
    )
    let detachSentRecord = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.DETACH_SENT_REC
    )
    let submitErrorMessage = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_ERROR_MSG
    )
    let submitSentRecord = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_SENT_RECORD
    )
    let amendmentCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.AMENDMENT_CODE
    )
    let messageFunction = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.MESSAGE_FUNCTION
    )
    let msgFunctionAdd = translate(
      Namespace.ACIHWY_MANIFEST,
      AciHighwayManifestKeys.MESSAGE_FUNCTION_ADD
    )
    let msgFunctionModify = translate(
      Namespace.ACIHWY_MANIFEST,
      AciHighwayManifestKeys.MESSAGE_FUNCTION_MODIFY
    )
    let msgFunctionDelete = translate(
      Namespace.ACIHWY_MANIFEST,
      AciHighwayManifestKeys.MESSAGE_FUNCTION_DELETE
    )
    let msgFunctionCorrected = translate(
      Namespace.ACIHWY_MANIFEST,
      AciHighwayManifestKeys.MESSAGE_FUNCTION_CORRECTED
    )
    let printA8A = translate(
      Namespace.ACIHWY_MANIFEST,
      AciHighwayManifestKeys.PRINT_A8A
    )
    let printA49 = translate(
      Namespace.ACIHWY_MANIFEST,
      AciHighwayManifestKeys.PRINT_A49
    )
    let printMultipleRecords = translate(
      Namespace.ACIHWY_MANIFEST,
      AciHighwayManifestKeys.PRINT_MULTIPLE_RECORDS
    )
    let shipperName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SHIPPER_NAME
    )

    let consigneeName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CONSIGNEE_NAME
    )

    let customsBroker = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CUSTOMS_BROKER
    )

    let entryNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ENTRY_NO
    )

    let attachCargoSectionHelper = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH_CARGO_SECTION_HELPER
    )

    return {
      ccn,
      cargoType,
      acquittalNo,
      createdDateTime,
      arrivalDateAndTime,
      arrivalDateAndTimeZone,
      tripNo,
      status,
      deleteButton,
      detachButton,
      submitButton,
      cancelButton,
      submitSuccessMsg,
      attachCargoSuccessMsg,
      detachSuccessMsg,
      editButton,
      editSentRecord,
      cloneButton,
      detachSentRecord,
      submitErrorMessage,
      submitSentRecord,
      amendmentCode,
      messageFunction,
      msgFunctionAdd,
      msgFunctionModify,
      msgFunctionDelete,
      msgFunctionCorrected,
      printA8A,
      printA49,
      printMultipleRecords,
      shipperName,
      consigneeName,
      customsBroker,
      entryNo,
      attachCargoSectionHelper
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  const columns = [
    {
      field: 'ccn',
      sortKey: 'ccn',
      title: [
        translatedTextsObject.ccn,
        translatedTextsObject.cargoType
      ].join(' / '),
      render: (rowData) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {rowData.ccn}
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            {[(rowData.cargoType === "OSC" ? "OTHER" : rowData.cargoType), rowData.acquittalNo]
              .filter((value) => value)
              .join(' • ')}
          </Typography>
        </Box>
      )
    },
    {
      field: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      field: 'consigneeName',
      title: translatedTextsObject.consigneeName
    },
    {
      title: 'Cargo Loaded In',
      render: (rowData) => {
        const selectedRows = tableRef.current.selectedRows
        const isSelected =
          selectedRows.length > 0
            ? selectedRows.map((rowData) => rowData.id).includes(rowData.id)
            : false

        return (
          <CargoLoadedIn
            cargoLoadedInList={cargoLoadedInList}
            isSelected={isSelected}
            isSentStatus={rowData.status === '1008'}
            onUpdateCargo={handleModifyCargo}
            onSubmitCargo={(data) => handleSubmitCargos([data])}
            cargo={rowData}
            translatedTextsObject={translatedTextsObject}
          />
        )
      }
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (rowData) => {
        const status = getStatusMetadata(rowData.status)

        if (rowData.status == '1000' && rowData.cargoType == 'REGULAR' && !rowData.entryNo) {
          status.color = "#ffc400"
          status.contrastColor = "#000000"
        }

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            <Chip
              label={status.label}
              size='small'
              style={{ color: status.contrastColor, backgroundColor: status.color, fontSize: 12 }}
            />
            {rowData.portMismatch && (
              <Chip key='first' label={"Port Mismatch"} size='small'
                style={{ color: '#ffffff', backgroundColor: '#DF2901', fontSize: 12 }}
              />
            )}
          </Box>
        )
      }
    },
    {
      title: 'Response message / Amendment code',
      render: (rowData) => {
        const selectedRows = tableRef.current.selectedRows
        const isSelected =
          selectedRows.length > 0
            ? selectedRows.map((rowData) => rowData.id).includes(rowData.id)
            : false

        return (
          <MessageFuncAndAmendmentCode
            amendmentCodeOptions={amendmentCodeOptions}
            isSelected={isSelected}
            isSentStatus={rowData.status === '1008'}
            onUpdateCargo={handleModifyCargo}
            onSubmitCargo={(data) => preSubmitCargos([data])}
            cargo={rowData}
            translatedTextsObject={translatedTextsObject}
          />
        )
      }
    },
    {
      field: 'entryNo',
      sortKey: 'entryNo',
      title: translatedTextsObject.entryNo,
      render: (rowData) => (
        <Typography component='div' variant='inherit'>
          {rowData.cargoType !== "CSA" ? rowData.entryNo : ""}
        </Typography>
      )
    },
    {
      field: 'customsBroker',
      sortKey: 'customsBroker',
      title: translatedTextsObject.customsBroker
    },
  ]

  function handleCreateCargo(data) {
    onSetLoading(true)

    createRecord.execute(
      AciHighwayCargoApiUrls.POST, CargoFormProperties.toServerDataFormat(data),
      (res) => {
        showNotification('success', 'Cargo created successfully.')
        setCargoDialog({ open: false, cargo: null, isView: false })
        tableRef.current.performRefresh()
      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      },
      () => onSetLoading(false)
    )
  }

  function handleEditCargo(data) {
    onSetLoading(true)

    updateRecord.execute(
      AciHighwayCargoApiUrls.PUT, CargoFormProperties.toServerDataFormat(data),
      () => {
        tableRef.current.performRefresh()

        setCargoDialog({ open: false, cargo: null, isView: false })
        showNotification('success', 'Cargo updated.')
      },
      (error) => {
        if (error.response.data.errorMessages) {
          error.response.data.errorMessages.forEach((errorMessage) => {
            showNotification('error', errorMessage)
          })
        }
      },
      () => onSetLoading(false)
    )
  }

  function handleCloneCargo(data) {
    data.cloneFromManifest = 'Y';

    createRecord.execute(
      AciHighwayCargoApiUrls.CLONE, data,
      (res) => {
        res.manifestId = manifestId

        res.arrivalTime = moment(data.arrivalDateAndTime).format('HH:mm');
        res.arrivalDate = moment(data.arrivalDateAndTime).format('YYYY-MM-DD');

        history.push({
          pathname: pathMap.ACI_HW_CARGO_ADD_VIEW,
          state: res
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDetachCargos(rows) {
    const result = { manifestId: Number(manifestId), cargoId: rows.map((cargo) => cargo.id) }

    securedSendRequest.execute(
      'POST', AciHighwayManifestApiUrls.DETACH, result,
      (response) => {
        const data = response.data
        if (data.errorMessages != null && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)

        } else if (data.errorMessages === null || data.errorMessages === undefined) {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }

          showNotification('success', translatedTextsObject.detachSuccessMsg)
        }
      },
      (error) => console.log(error)
    )
  }

  function handleDeleteCargo() {
    if (confirmDialog.cargo) {
      deleteRecord.execute(
        AciHighwayCargoApiUrls.DELETE, confirmDialog.cargo,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, cargo: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handleMassDeleteCargo() {
    if (massDeleteConfirmDialog.idList) {
      setMassDeleteConfirmDialog({ open: false })
      sweetalert("Cargo(s) deletion in progress...", "", "success");
      securedSendRequest.execute('POST',
        AciHighwayCargoApiUrls.MASS_DELETE,
        massDeleteConfirmDialog.idList,
        () => {
          showNotification('success', 'Mass delete succeeded')
          setMassDeleteConfirmDialog({ open: false, idList: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Mass delete failed.')
          console.log(error)
        }
      )
    }
  }

  function handleModifyCargo(cargos) {
    try {
      if (tableRef.current) {
        tableRef.current.updateData(cargos)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handlePrintA49(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST', AciHighwayCargoApiUrls.PRINTA49, data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACIHW_CARGO_A49_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined, config, null
    )
  }

  function handlePrintA8A(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST', AciHighwayCargoApiUrls.PRINT, data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACIHW_CARGO_A8A_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined, config, null
    )
  }

  async function handleBulkPrintA8A(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        data.forEach(record => {
          record.fileForUserId = fileForUserDetails.fileForUserId
          record.fileForUserLoginId = fileForUserDetails.fileForUserLoginId
          record.fileForUserPartyId = fileForUserDetails.fileForUserPartyId
        })
      }

      securedSendRequest.execute('POST', AciHighwayCargoApiUrls.BULK_PRINT_A8A, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'ACIHW_CARGO_A8A_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.zip'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined, config, null
      )
    }
  }

  function preSubmitCargos(cargos) {
    if (isSuperParty) {
      setSubmitDialog({ open: true, cargos: cargos })
    } else {
      cargos.forEach(e => {
        e.chargeAs = 'REGULAR'
      })
      handleSubmitCargos(cargos)
    }
  }

  function preSubmitAllCargos(data) {
    if (isSuperParty) {
      setMassSubmit(true)
      setSubmitDialog({ open: true, cargos: data })
    } else {
      data.chargeAs = 'REGULAR'
      handleSubmitAllCargos(data)
    }
  }

  function handleSubmitCargos(cargos) {
    try {
      tableRef.current.setLoading(true)

      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        cargos.forEach(data => {
          data.fileForUserId = fileForUserDetails.fileForUserId;
          data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        });
      }

      securedSendRequest.execute(
        'POST', AciHighwayManifestApiUrls.SUBMIT_ALL, cargos,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }
          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error),
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function handleSubmitAllCargos(data) {
    try {
      tableRef.current.setLoading(true)

      const formData = new FormData()
      formData.append('manifestId', Number(data.manifestId))
      formData.append('status', data.status)
      formData.append('chargeAs', data.chargeAs)

      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails));
      } else {
        formData.append("fileForUserDetails", "{}");
      }

      securedSendRequest.execute(
        'POST', AciHighwayManifestApiUrls.SUBMIT_ALL, formData,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }
          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error),
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
              onClick: () => setSubmitAllCargoMsgDialog({ open: true })
            },
            label: 'Submit all cargo'
          },          
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'upload']} />,
              onClick: () => setUploadCargoDialog({ open: true, tableRef: tableRef })
            },
            label: 'Upload new cargo'
          },
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () =>
                setCargoDialog({ open: true, cargo: null, isView: false })
            },
            label: 'Create new cargo'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'link']} />,
              onClick: () => setAttachCargoDialog(true)
            },
            label: 'Attach cargo'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            iconButtonProps: { icon: ['fal', 'minus-circle'], style: { color: theme.palette.error.main } },
            onClick: (rowData) => handleDetachCargos([rowData]),
            tooltip: () => translatedTextsObject.detachButton
          }
        ]}
        fetch={{ url: AciHighwayCargoApiUrls.CUSTOM_SEARCH }}
        fetchFilters={[{ field: 'manifestId', operator: EQUAL, value: manifestId }]}
        onPostFetch={(data) => {
          data.forEach((cargo) => {
            if (cargo.cargoLoadedInTrailer) {
              cargo.cargoLoadedIn = ("L" + cargo.cargoLoadedInTrailer)
            } else if (cargo.cargoLoadedInTruck) {
              cargo.cargoLoadedIn = ("V" + cargo.cargoLoadedInTruck)
            }
          })
          return data;
        }}
        onRowClick={(rowData) =>
          setCargoDialog({ open: true, cargo: CargoFormProperties.toClientDataFormat(rowData), isView: true })
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) => setCargoDialog({ open: true, cargo: rowData, isView: false }),
            tooltip: (rowData) => rowData.status === '1008' ? translatedTextsObject.editSentRecord : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneCargo
          },
          {
            disabled: (rowData) => rowData.status !== '1005',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) => setConfirmDialog({ open: true, cargo: rowData }),
            tooltip: (rowData) => rowData.status !== '1005' ? 'Only draft status can be deleted' : null
          },
          {
            label: translatedTextsObject.printA8A,
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintA8A
          },
          {
            label: translatedTextsObject.printA49,
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintA49
          }
        ]}
        selectActions={selectedTopBar}
        search={{ field: 'ccn', operator: LIKE }}
        showNotification={showNotification}
        tableRef={tableRef}
        helperText={translatedTextsObject.attachCargoSectionHelper}
      />
      <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() => history.push(pathMap.ACIHWY_MANIFEST_LIST_VIEW)}
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onChangeStep(step - 1)}
                  size='medium'
                >
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton color='primary' onClick={() => onChangeStep(step + 1)} size='medium'>
                  Add Status Notification (Optional)
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <AttachCargoDialog
        columns={columns}
        manifestId={manifestId}
        open={attachCargoDialog}
        onAttachCargo={() => {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }
        }}
        onClose={() => setAttachCargoDialog(false)}
        showNotification={showNotification}
      />
      <CargoDialog
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
        isView={cargoDialog.isView}
        manifestId={manifestId}
        firstExpectedPortValue={firstExpectedPortValue}
        expectedArrivalDateTimeValue={expectedArrivalDateTimeValue}
        expectedArrivalTimeZoneValue={expectedArrivalTimeZoneValue}
        loading={loading}
        onClose={() =>
          setCargoDialog({ open: false, cargo: null, isView: false })
        }
        onCreateCargo={handleCreateCargo}
        onEditCargo={handleEditCargo}
        open={cargoDialog.open}
        cargo={cargoDialog.cargo}
        showNotification={showNotification}
        manifestCarrierCode={manifestCarrierCode}
      />
      <SetResponseMessageDialog
        amendmentCodeOptions={amendmentCodeOptions}
        onClose={() => setSetResponseMsgDialog({ open: false, cargos: [] })}
        onSaveCargo={(cargos) => {
          handleModifyCargo(cargos)
          setSetResponseMsgDialog({ open: false, cargos: [] })
        }}
        onSubmitCargo={preSubmitCargos}
        open={setResponseMsgDialog.open}
        cargos={setResponseMsgDialog.cargos}
        translatedTextsObject={translatedTextsObject}
      />
      <CargoUploadDialog
        manifestId={manifestId}
        open={uploadCargoDialog}
        onClose={() => setUploadCargoDialog(false)}
        showNotification={showNotification}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, cargo: null })}
        confirmDialog={handleDeleteCargo}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ConfirmDialog
        isConfirmDialogOpen={massDeleteConfirmDialog.open}
        closeDialog={() => setMassDeleteConfirmDialog({ open: false, idList: null })}
        confirmDialog={handleMassDeleteCargo}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, cargos: [] })}
        onSubmit={isMassSubmit ? handleSubmitAllCargos : handleSubmitCargos}
        open={submitDialog.open}
        items={submitDialog.cargos}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
      <SubmitAllCargoMessageDialog
        manifestId={manifestId}
        onClose={() => setSubmitAllCargoMsgDialog({ open: false })}
        onSubmitCargo={preSubmitAllCargos}
        open={submitAllCargoMsgDialog.open}
        translatedTextsObject={translatedTextsObject}
      />        
    </>
  )
}

export default CargoAttachTable

function MessageFuncAndAmendmentCode(props) {
  const {
    amendmentCodeOptions,
    isSelected,
    isSentStatus,
    onSubmitCargo,
    cargo,
    onUpdateCargo,
    translatedTextsObject
  } = props

  const theme = useTheme()
  const classes = useStyles()

  function handleSubmitButtonClick(event) {
    event.stopPropagation()
    onSubmitCargo(cargo)
  }

  return (
    <Grid alignItems='center' container spacing={1} wrap='nowrap'>
      <Grid item xs='auto'>
        <Select
          className={classes.select}
          disabled={isSentStatus || !isSelected}
          native
          onChange={(event) =>
            onUpdateCargo({ ...cargo, messageFunction: event.target.value })
          }
          onClick={(event) => event.stopPropagation()}
          size='small'
          value={cargo.messageFunction || ''}
          variant='outlined'
        >
          <option value='' disabled>
            {translatedTextsObject.messageFunction}
          </option>
          <option value='00'>{translatedTextsObject.msgFunctionAdd}</option>
          <option value='04'>{translatedTextsObject.msgFunctionModify}</option>
          <option value='03'>{translatedTextsObject.msgFunctionDelete}</option>
          <option value='CO'>
            {translatedTextsObject.msgFunctionCorrected}
          </option>
        </Select>
      </Grid>
      {cargo.messageFunction === 'CO' && (
        <Grid item xs='auto'>
          <Select
            className={classes.select}
            disabled={isSentStatus || !isSelected}
            native
            onChange={(event) =>
              onUpdateCargo({ ...cargo, amendmentCode: event.target.value })
            }
            onClick={(event) => event.stopPropagation()}
            size='small'
            value={cargo.amendmentCode || ''}
            variant='outlined'
          >
            <option value='' disabled>
              {translatedTextsObject.amendmentCode}
            </option>
            {amendmentCodeOptions.map((option) => (
              <option key={option.value} value={option.code}>
                {option.desc}
              </option>
            ))}
          </Select>
        </Grid>
      )}
      <Grid item xs='auto'>
        <Tooltip
          arrow
          placement='top'
          title={
            isSentStatus
              ? translatedTextsObject.submitSentRecord
              : translatedTextsObject.submitButton
          }
        >
          <span>
            <CngIconButton
              disabled={isSentStatus || !isSelected || !cargo.messageFunction}
              size='small'
              icon={['fal', 'arrow-alt-right']}
              onClick={handleSubmitButtonClick}
              type='outlined'
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

function CargoLoadedIn(props) {
  const {
    cargoLoadedInList,
    isSelected,
    isSentStatus,
    onSubmitCargo,
    cargo,
    onUpdateCargo,
    translatedTextsObject
  } = props

  const theme = useTheme()
  const classes = useStyles()

  function handleSubmitButtonClick(event) {
    event.stopPropagation()
    onSubmitCargo(cargo)
  }

  return (
    <Grid alignItems='center' container spacing={1} wrap='nowrap'>
      <Grid item xs='auto'>
        <Select
          className={classes.select}
          disabled={isSentStatus || !isSelected}
          native
          onChange={(event) =>
            onUpdateCargo({ ...cargo, cargoLoadedIn: event.target.value })
          }
          onClick={(event) => event.stopPropagation()}
          size='small'
          value={cargo.cargoLoadedIn}
          variant='outlined'
        >
          <option value='' disabled>
            {'Cargo Loaded In'}
          </option>
          {cargoLoadedInList.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}
