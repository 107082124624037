import { components, constants, useTranslation, useServices } from 'cng-web-lib'
import moment from 'moment-timezone'
import AciHighwayResponseApiUrls from '../../../apiUrls/AciHighwayResponseApiUrls'
import React, { useEffect,useState } from 'react'
import AciHighwayResponse from '../../../constants/locale/key/AciHighwayResponse'
import Namespace from '../../../constants/locale/Namespace'
import { FileForUserGetPartyId,FileForUserGetUserDetails,GetFilterByUserIdCriteria } from '../../../common/FileForUserCommon'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../../components/aciacehighway/Table'
import { format } from 'date-fns'

const { table: { useFetchCodeMaintenanceLookup } } = components

const {
  filter: { EQUAL, LIKE, BETWEEN, IN },
  CodeMaintenanceType
} = constants

function TablePage(props) {

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const {securedSendRequest} = useServices()

  const { showNotification } = props

  const [lookups, setLookups] = useState(null)
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_RESPONSE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const location = useLocation();

  let module = "";

  const ehblModule = location.pathname.toString().indexOf("ehbl")!=-1 ? true : false
  const wacmModule = location.pathname.toString().indexOf("wacmD4Notices")!=-1 ? true : false

  let filter = []
  
  if(ehblModule){
    filter = GetFilterByUserIdCriteria("EHBL")
    module = "EHBL"
  }else if(wacmModule){
    module = "EHBL"
  }else{
    module = "ACIHW"
  }

  
  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'ACI_HW_D4_NOTICE_REASON_CODE' }
      ])
    ]).then(([noticeReasonCode]) => {
      setLookups({ noticeReasonCode })
    })
  }, [])
  
  function makeTranslatedTextsObject() {
    let responseId = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.RESPONSE_ID
    )
    let noticeReasonCode = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.NOTICE_REASON_CODE
    )
    let cbsaRefNo = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.CBSA_REF_NO
    )
    let portOfClearance = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.PORT_OF_CLEARANCE
    )
    let portOfReport = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.PORT_OF_REPORT
    )
    let portOfArrival = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.PORT_OF_ARRIVAL
    )
    let portOfDestination = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.PORT_OF_DESTINATION
    )
    let receivedDate = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.RECEIVED_DATE
    )
    let cbsaResponseDate = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.CBSA_RESPONSE_DATE
    )
    let printD4 = translate(
      Namespace.ACI_HIGHWAY_RESPONSE,
      AciHighwayResponse.PRINT_D4
    )

    return {
      responseId,
      noticeReasonCode,
      cbsaRefNo,
      portOfClearance,
      portOfReport,
      portOfArrival,
      portOfDestination,
      receivedDate,
      cbsaResponseDate,
      printD4
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: "responseId",
      title: translatedTextsObject.responseId,
      sortKey: "responseId"
    },
    {
      field: "noticeReasonCode",
      title: translatedTextsObject.noticeReasonCode,
      sortKey: "noticeReasonCode",
      render: (rowData) => getLookupValue('noticeReasonCode', rowData.noticeReasonCode)
    },
    {
      field: "cbsaRefNo",
      title: translatedTextsObject.cbsaRefNo,
      sortKey: "cbsaRefNo"
    },
    {
      field: "portOfClearance",
      title: translatedTextsObject.portOfClearance,
      sortKey: "portOfClearance"
    },
    {
      field: "portOfReport",
      title: translatedTextsObject.portOfReport,
      sortKey: "portOfReport"
    },
    {
      field: "portOfArrival",
      title: translatedTextsObject.portOfArrival,
      sortKey: "portOfArrival"
    },
    {
      field: "portOfDest",
      title: translatedTextsObject.portOfDestination,
      sortKey: "portOfDest"
    },
    {
      field: "receiveDateTime",
      title: translatedTextsObject.receivedDate,
      sortKey: "receiveDateTime",
      render: (rowData) => (
        rowData.receiveDateTime && moment(rowData.receiveDateTime).format('DD/MM/YYYY HH:mm:ss')
      )
    },
    {
      field: "cbsaProcessDateTime",
      title: translatedTextsObject.cbsaResponseDate,
      sortKey: "cbsaProcessDateTime",
      render: (rowData) => (
        rowData.cbsaProcessDateTime &&
        moment(rowData.cbsaProcessDateTime).tz("Canada/Eastern").format('D MMM YYYY HH:mm:ss')
      )
    }
  ]

  const filters = [
    {
      name: 'responseId',
      label: translatedTextsObject.responseId,
      type: 'textfield',
      operatorType: LIKE

    },
    {
      name: 'noticeReasonCode',
      label: translatedTextsObject.noticeReasonCode,
      type: 'checkbox',
      operatorType: IN,
      options: [
        { label: '8000 - Deconsolidation', value: '8000', filterValue: { value: '8000' }, fullWidth: true },
        { label: '0001 - Matched', value: '0001', filterValue: { value: '0001' }, fullWidth: true },
        { label: '0002 - Not Matched', value: '0002', filterValue: { value: '0002' }, fullWidth: true },
        { label: '0003 - Cargo Complete', value: '0003', filterValue: { value: '0003' }, fullWidth: true },
        { label: '0004 - Document Package Complete', value: '0004', filterValue: { value: '0004' }, fullWidth: true },
        { label: '0005 - Document Not on File', value: '0005', filterValue: { value: '0005' }, fullWidth: true },
        { label: '0010 - Reported', value: '0010', filterValue: { value: '0010' }, fullWidth: true },
        { label: '0011 - Arrived', value: '0011', filterValue: { value: '0011' }, fullWidth: true },
        { label: '0013 - Released', value: '0013', filterValue: { value: '0013' }, fullWidth: true },
        { label: '0016 - Authorized to Deliver', value: '0016', filterValue: { value: '0016' }, fullWidth: true },
        { label: '0020 - Held for CBSA', value: '0020', filterValue: { value: '0020' }, fullWidth: true },
        { label: '0040 - FAST Lane Eligible', value: '0040', filterValue: { value: '0040' }, fullWidth: true },
        { label: '0041 - FAST Lane Ineligible', value: '0041', filterValue: { value: '0041' }, fullWidth: true },
      ]
    },
    {
      name: 'cbsaRefNo',
      label: translatedTextsObject.cbsaRefNo,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: 'portOfClearance',
      label: translatedTextsObject.portOfClearance,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: 'portOfReport',
      label: translatedTextsObject.portOfReport,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: 'portOfArrival',
      label: translatedTextsObject.portOfArrival,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: 'portOfDest',
      label: translatedTextsObject.portOfDestination,
      type: 'textfield',
      operatorType: LIKE
    },
    {
      name: 'receiveDateTime',
      label: translatedTextsObject.receivedDate,
      type: 'daterange',
      operatorType: BETWEEN
    },
    {
      name: 'cbsaProcessDateTime',
      label: translatedTextsObject.cbsaResponseDate,
      type: 'daterange',
      operatorType: BETWEEN
    }
  ]

  function handleDownload(rowData) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      rowData.fileForUserId = fileForUserDetails.fileForUserId;
      rowData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      rowData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AciHighwayResponseApiUrls.PRINTD4,
      rowData,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'D4_REPORT_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"

        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  return (
    <Table
      columns={columns}
      compact
      filters={filters}
      fetch={{ url: AciHighwayResponseApiUrls.D4_GET }}
      fetchFilters={[
        { field: 'moduleName', operator: 'equal', value: module },
        { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
        ...filter
      ]}
      showNotification={showNotification}
      sortConfig={{
        type: 'column',
        defaultField: 'createdDate',
        defaultDirection: 'DESC'
      }}
      rowActions={[
        {
          icon: <FontAwesomeIcon icon={['fal', 'print']} />,
          label: translatedTextsObject.printD4,
          onClick: handleDownload
        }
      ]}
    />
  )
}

export default TablePage
